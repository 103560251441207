import React, { useState } from 'react';

import { IonAlert } from '@ionic/react';

interface CustomPageProps extends React.PropsWithChildren<{
    mensaje: string;
    callback: Function
}> {}

const MensajeUsuario: React.FC<CustomPageProps> = (props) => {

    const [show, setShow] = useState(true);

    return(
        <IonAlert
            isOpen={show}
            onDidDismiss={() => { setShow(false); props.callback(); } }
            header={'Mensaje Usuario'}
            message={props.mensaje}
            buttons={[ 'Entendido' ]}
        />
    );

};

export default MensajeUsuario;
