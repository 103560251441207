class UrlUtils {

    /**
     * Devuelve imagen avatar asociada al usuario.
     */
    static getUrlBack(match: any) {
        // devolvemos URL
        return `/reservas/listado/${match.params.year}/${match.params.month}`;;
    } // getUrlBack

    /**
     * Devuelve URL navegación.
     * 
     * @param url 
     * @param filter 
     * @param reserva 
     */
    static getUrlNavegacion(url: string, filter: any, reserva: any) {
        return `${url}/${filter.year}/${filter.month}/${reserva.reserva || '1'}`;
    } // getUrlNavegacion
    
};

export default UrlUtils;
