import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import {
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonItem,
    IonLabel,
    IonImg,
    IonInput,
    IonTextarea,
    IonButton,
    IonCheckbox
} from '@ionic/react';

import { Reserva } from '../../core/types/Reserva';

import UrlUtils from '../../core/UrlUtils';

import Fecha from '../../components/Fecha';
import PageLayout from '../../layout/PageLayout';

import Styles from './Styles.module.css';

interface ReservaDetallePageProps extends RouteComponentProps<{
    year:  string,
    month: string
    id:    string
}> {}

const NuevaReserva: React.FC<ReservaDetallePageProps> = ({match}) => {

    const [reserva, setReserva] = useState<Reserva>({
        "rsvCoReserva": 0,
        "datos": {
            "rvdCoReserva": 0,
            "rvdCcNombre": '',
            "rvdCcApellidos": '',
            "rvdDsDireccion": '',
            "rvdCcEmail": '',
            "rvdCcTelefono": '',
            "rvdCaInvitados": 0,
            "rvdItAlumno": 0,
            "rvdDsAlumno": '',
            "rvdDsComentarios": ''
        },
        "estado": {
            "stsCoEstado": 1,
            "stsCcNombre": "STS_CC_ABIERTA",
            "stsDsDescripcion": "Reserva Abierta"
        },
        "tipo": {
            "tsvCoTipo": 100,
            "tsvCcNombre": "CPLATERO_PARK",
            "tsvCcDescripcion": "Platero Park - Parque de bolas"
        },
        "fecha": {
            "date": "2020-02-28",
            "reserva": 0,
            "badge": false
        }
    });

    function setDatosReserva(clave: string, valor: any) {
        setReserva({
            ...reserva,
            datos: {
                ...reserva.datos,
                rvdDsAlumno: clave === 'rvdItAlumno' && valor === 1 ? reserva.datos.rvdDsAlumno : '',
                [clave] : valor,
            }
        });
    }

    return (
        <PageLayout titulo="Nueva Reserva" back={UrlUtils.getUrlBack(match)}>
            {reserva != null &&
            <IonCard>
                <IonCardHeader>
                    <IonImg src={"assets/images/plateroteca-logo.png"} />
                </IonCardHeader>
                <IonCardContent>
                    <IonItem>
                        <IonLabel className={Styles.label}>Fecha Reserva</IonLabel>
                        <IonLabel><Fecha date={reserva.fecha.date} /></IonLabel>
                    </IonItem>
                    <IonItem>
                    <IonLabel className={Styles.label}>Estado</IonLabel>
                        <IonLabel>{reserva.estado.stsDsDescripcion}</IonLabel>
                    </IonItem>
                    {/** Input Nombre */}
                    <IonItem>
                        <IonLabel className={Styles.label} position="stacked">Nombre</IonLabel>
                        <IonInput id="rvdCcNombre" name="rvdCcNombre" type="text"
                            value={reserva.datos.rvdCcNombre}
                            onIonChange={(event) => {
                                setDatosReserva((event.target as HTMLInputElement).name, (event.target as HTMLInputElement).value);
                            }}
                        />
                    </IonItem>
                    {/** Input Apellidos */}
                    <IonItem>
                        <IonLabel className={Styles.label} position="stacked">Apellidos</IonLabel>
                        <IonInput id="rvdCcApellidos" name="rvdCcApellidos" type="text"
                            value={reserva.datos.rvdCcApellidos}
                            onIonChange={(event) => {
                                setDatosReserva((event.target as HTMLInputElement).name, (event.target as HTMLInputElement).value);
                            }}
                        /> 
                    </IonItem>
                    {/** INPUT Dirección */}
                    <IonItem>
                        <IonLabel className={Styles.label} position="stacked">Dirección</IonLabel>
                        <IonInput id="rvdDsDireccion" name="rvdDsDireccion" type="text"
                            value={reserva.datos.rvdDsDireccion}
                            onIonChange={(event) => {
                                setDatosReserva((event.target as HTMLInputElement).name, (event.target as HTMLInputElement).value);
                            }}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel className={Styles.label} position="stacked">Email</IonLabel>
                        <IonInput id="rvdCcEmail" name="rvdCcEmail" type="text" inputMode="email"
                            value={reserva.datos.rvdCcEmail}
                            onIonChange={(event) => {
                                setDatosReserva((event.target as HTMLInputElement).name, (event.target as HTMLInputElement).value);
                            }}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel className={Styles.label} position="stacked">Teléfono</IonLabel>
                        <IonInput id="rvdCcTelefono" name="rvdCcTelefono" type="text"inputMode="tel"
                            value={reserva.datos.rvdCcTelefono}
                            onIonChange={(event) => {
                                setDatosReserva((event.target as HTMLInputElement).name, (event.target as HTMLInputElement).value);
                            }}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel className={Styles.label} position="stacked">Nº Invitados</IonLabel>
                        <IonInput id="rvdCaInvitados" name="rvdCaInvitados" type="number" inputMode="numeric" min="0" max="70" step="1"
                            value={reserva.datos.rvdCaInvitados}
                            onIonChange={(event) => {
                                setDatosReserva((event.target as HTMLInputElement).name, (event.target as HTMLInputElement).value);
                            }}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel className={Styles.label} position="stacked">Alumno/a Colegio</IonLabel>
                        <IonCheckbox id="rvdItAlumno" name="rvdItAlumno"
                            checked={reserva.datos.rvdItAlumno === 1}
                            onIonChange={(event) => {
                                setDatosReserva((event.target as HTMLInputElement).name, (event.target as HTMLInputElement).checked ? 1 : 0);
                            }}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel className={Styles.label} position="stacked">Datos del Alumno/a</IonLabel>
                        <IonInput id="rvdDsAlumno" name="rvdDsAlumno" type="text"
                            value={reserva.datos.rvdDsAlumno}
                            readonly={reserva.datos.rvdItAlumno === 0}
                            onIonChange={(event) => {
                                setDatosReserva((event.target as HTMLInputElement).name, (event.target as HTMLInputElement).value);
                            }}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel className={Styles.label} position="stacked">Comentarios</IonLabel>
                        <IonTextarea id="rvdDsComentarios" name="rvdDsComentarios"
                            value={reserva.datos.rvdDsComentarios} rows={5}
                            onIonChange={(event) => {
                                setDatosReserva((event.target as HTMLInputElement).name, (event.target as HTMLInputElement).value);
                            }}
                        >
                            
                        </IonTextarea>
                    </IonItem>
                    <IonButton
                        id="reservar"
                        expand="block"
                        onClick={() => {
                            console.clear();
                            console.log(JSON.stringify(reserva, null, 2));
                        }}
                    >
                        Reservar
                    </IonButton>
                </IonCardContent>
            </IonCard>
            }
        </PageLayout>
    );

};

export default NuevaReserva;
