import React from 'react';

import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonSpinner,
    IonAvatar,
    IonImg
} from '@ionic/react';

import Constantes from '../config/Constantes';
import UsuarioUtils from '../core/UsuarioUtils';

const PageLayout = (props: any) => {

    const utils = UsuarioUtils.getInstance();

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    { props.titulo !== Constantes.APP_NAME &&
                        <IonButtons slot="start"><IonBackButton defaultHref={props.back} /></IonButtons>
                    }
                    <IonTitle><b>{props.titulo}</b></IonTitle>
                    { props.titulo !== Constantes.APP_NAME &&
                        <IonAvatar slot="end">
                            <IonImg src={utils.getImagenAvatar()} title={"Javier Cantos"} />
                        </IonAvatar>
                    }
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                {props.loading && <IonSpinner name="dots" />}
                {props.children}
            </IonContent>
        </IonPage>
    )
}

export default PageLayout;
