import React, { useState, useEffect } from 'react';
import { LoadScript, GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { IonModal, IonButton, IonLabel } from '@ionic/react';

import axios from 'axios';

import Constantes from '../config/Constantes';

interface CustomPageProps extends React.PropsWithChildren<{
    direccion: string
    callback: Function
}> {}

const sendGetRequest = async (direccion: string) => {
    const response = await axios({
        url: `${Constantes.URL_API_GOOGLE_MAP}&address=${direccion}+Málaga`,
        method: 'GET'
    });
    return response.data;
};

const MapaGoogle = (props: any) => {
    return(
        <LoadScript
            googleMapsApiKey={Constantes.API_KEY_GOOGLE_MAP}>
            <GoogleMap
                zoom={15}
                center={props.location}
                mapContainerStyle={{
                    height: "100%",
                    width: "100%"
                }}
            >
                <Marker
                    position={props.location} />
                <InfoWindow
                    position={props.location}>
                    <IonLabel>{props.direccion}</IonLabel>
                </InfoWindow>
            </GoogleMap>
        </LoadScript>
    );
};

const Localizacion: React.FC<CustomPageProps> = (props) => {

    const [show, setShow] = useState(true);
    const [direccion, setDireccion] = useState();
    const [location, setLocation] = useState({
        lat: 36.7219211,
        lng: -4.3456931
     });

     useEffect(() => {
        // invocamos API google maps
        sendGetRequest(props.direccion)
            .then((data) => {
                if (data.status === 'OK') {
                    // inicializamos datos direccion
                    setDireccion(data.results[0].formatted_address);
                    setLocation(data.results[0].geometry.location);    
                } // if
            });
     }, [props.direccion]);

    return(
        <IonModal isOpen={show}>
            <MapaGoogle direccion={direccion} location={location} />
            <IonButton onClick={() => { setShow(false); props.callback(); }}>Cerrar</IonButton>
        </IonModal>
    );

};

export default Localizacion;
