import React, { useEffect, useState } from 'react';

import { RouteComponentProps } from 'react-router-dom';

import {
    IonButton,
    IonList,
    IonItem,
    IonInput,
    IonGrid,
    IonRow,
    IonCol
} from '@ionic/react';

import Constantes from '../../config/Constantes';

import PageLayout from '../../layout/PageLayout';

import axios from 'axios';
import UrlUtils from '../../core/UrlUtils';
import Fecha from '../../components/Fecha';

interface PageProps extends RouteComponentProps<{
    year: string
    month: string
}> {}

const sendGetRequest = async (filter: any) => {
    const response = await axios({
        url: `${Constantes.URL_BASE}/calendario?year=${filter.year}&month=${filter.month}&itHistory=true`,
        method: 'GET',
        headers: {
            'X-Debug-Mode': 'true'
        }
    });
    return response.data;
};

const ListadoReservas: React.FC<PageProps> = ({match}) => {

    const [filter, setFilter] = useState({
        'year': parseInt(match.params.year),
        'month': parseInt(match.params.month)
    });
    const [reservas, setReservas] = useState([]);

    useEffect(() => {
        async function loadInfo() {
            setReservas(await sendGetRequest(filter));
        }
        loadInfo();
    }, [filter]);

    return (
        <PageLayout titulo="Listado Reservas" back="/home">
            <IonGrid>
                <IonRow>
                    <IonCol size="3" />
                    <IonCol>
                        <IonButton size="small"
                            onClick={() => { setFilter({ ...filter, year: --filter.year }); }}>
                            -
                    </IonButton>
                    </IonCol>
                    <IonCol>
                        <IonInput
                            inputmode={"numeric"} clearOnEdit={true}
                            name="year" value={filter.year.toString()}
                        />
                    </IonCol>
                    <IonCol>
                        <IonButton size="small"
                            onClick={() => { setFilter({ ...filter, year: ++filter.year }); }}>
                            +
                    </IonButton>
                    </IonCol>
                    <IonCol>
                        <IonButton size="small"
                            onClick={() => { setFilter({ ...filter, month: --filter.month }); }}>
                            -
                    </IonButton>
                    </IonCol>
                    <IonCol>
                        <IonInput
                            inputmode={"numeric"} clearOnEdit={true}
                            name="month" value={filter.month.toString()}
                        />
                    </IonCol>
                    <IonCol>
                        <IonButton size="small"
                            onClick={() => { setFilter({ ...filter, month: ++filter.month }); }}>
                            +
                    </IonButton>
                    </IonCol>
                    <IonCol size="3" />
                </IonRow>
                <IonRow>
                    <IonCol>
                        <IonList color="primary">
                            {
                                reservas.map((reserva, idx) => {
                                    return (
                                        <IonItem key={idx}>
                                            <Fecha date={reserva['date']} type={'SDF2'} />
                                            {!reserva['badge']
                                                ?
                                                <IonButton href={UrlUtils.getUrlNavegacion('/reservas/nueva', filter, reserva)} color="primary" slot="end">Reservar</IonButton>
                                                :
                                                <IonButton href={UrlUtils.getUrlNavegacion('/reservas/detalle', filter, reserva)} color="secondary" slot="end">Detalle</IonButton>
                                            }
                                        </IonItem>
                                    );
                                })
                            }
                        </IonList>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </PageLayout>
    );    

};

export default ListadoReservas;
