import React, { Fragment } from 'react';

const Fecha = (props: any) => {
    return(
        <Fragment>
            {/** Fecha formato DD/MM/AAAA */}
            { props.type === 'SDF1' && new Intl.DateTimeFormat('es-ES', { year: 'numeric', month: 'long', day: 'numeric' }).format(new Date(props.date)) }
            {/** Fecha formato DD/MM/AAAA */}
            { props.type === 'SDF2' && new Intl.DateTimeFormat('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(props.date)) }
        </Fragment>
    );
}

export default Fecha;
