import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import {
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonItem,
    IonLabel,
    IonImg
} from '@ionic/react';

import axios from 'axios';

import Fecha from '../../components/Fecha';
import Localizacion from '../../components/Localizacion';
import PageLayout from '../../layout/PageLayout';

import Constantes from '../../config/Constantes';
import UrlUtils from '../../core/UrlUtils';
import UsuarioUtils from '../../core/UsuarioUtils';

import Styles from './Styles.module.css';

interface ReservaDetallePageProps extends RouteComponentProps<{
    yeasr: string,
    month: string
    id:    string
}> {}

const sendGetRequest = async (idCliente: number, id: any) => {
    const response = await axios({
        url: `${Constantes.URL_BASE}/reservas/${id}`,
        method: 'GET',
        headers: {
            'token': idCliente,
            'X-Debug-Mode': true
        }
    });
    return response.data;
};

const DetalleReserva: React.FC<ReservaDetallePageProps> = ({match}) => {

    const idUsuario = UsuarioUtils.getInstance().getIdUsuario();

    const [showMapa, setShowMapa] = useState(false);

    const [loading, setLoading] = useState(true);
    const [reserva, setReserva] = useState();

    useEffect(() => {
        sendGetRequest(idUsuario, match.params.id)
        .then(data => {
            setReserva(data);
            setLoading(false);
        });
    }, [idUsuario, match.params.id]);

    return (
        <PageLayout titulo="Detalle Reserva" back={UrlUtils.getUrlBack(match)} loading={loading}>
            {reserva != null &&
            <IonCard>
                <IonCardHeader>
                    <IonImg src={"assets/images/plateroteca-logo.png"} />
                </IonCardHeader>
                <IonCardContent>
                    <IonItem>
                        <IonLabel className={Styles.label}>Fecha Reserva</IonLabel>
                        <IonLabel><Fecha date={reserva.fecha.date} type={'SDF1'} /></IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel className={Styles.label}>Estado</IonLabel>
                        <IonLabel>{reserva.estado.stsDsDescripcion}</IonLabel>
                    </IonItem>
                    {reserva.datos != null &&
                    <>
                    <IonItem>
                        <IonLabel className={Styles.label}>Nombre / Apellidos</IonLabel>
                        <IonLabel>{reserva.datos.rvdCcNombre + ' ' + reserva.datos.rvdCcApellidos}</IonLabel>
                    </IonItem>
                    <IonItem onClick={() => { setShowMapa(true); }}>
                        <IonLabel className={Styles.label}>Dirección</IonLabel>
                        <IonLabel>{reserva.datos.rvdDsDireccion}</IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel className={Styles.label}>Email</IonLabel>
                        <IonLabel>{reserva.datos.rvdCcEmail}</IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel className={Styles.label}>Teléfono</IonLabel>
                        <IonLabel>{reserva.datos.rvdCcTelefono}</IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel className={Styles.label}>Nº Invitados</IonLabel>
                        <IonLabel>{reserva.datos.rvdCaInvitados}</IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel className={Styles.label}>Alumno/a Colegio</IonLabel>
                        <IonLabel>{reserva.datos.rvdItAlumno ? 'Sí' : 'No'}</IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel className={Styles.label}>Datos del Alumno/a</IonLabel>
                        <IonLabel>{reserva.datos.rvdDsAlumno}</IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel className={Styles.label}>Comentarios</IonLabel>
                        <IonLabel>{reserva.datos.rvdDsComentarios}</IonLabel>
                    </IonItem>
                    </>
                    }
                </IonCardContent>
            </IonCard>
            }
            {showMapa &&
                <Localizacion
                    direccion={reserva.datos.rvdDsDireccion}
                    callback={() => { setShowMapa(false); }}
                />
            }
        </PageLayout>
    );

};

export default DetalleReserva;
