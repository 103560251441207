import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { IonIcon, IonInput, IonItem, IonLabel, IonButton, IonLoading } from '@ionic/react';
import { IonFab, IonFabButton } from '@ionic/react';

import axios from 'axios';
import Pusher from 'pusher-js';

import Constantes from '../config/Constantes';
import PageLayout from '../layout/PageLayout';
import UsuarioUtils from '../core/UsuarioUtils';

import MensajeUsuario from '../components/MensajeUsuario';

const pusher = new Pusher('1adcc945e2bddea0d74e', {
  cluster: 'eu',
  forceTLS: true
});

const channel = pusher.subscribe('PLATEROTECA');

const dameUrlReservas = () => {
  return "/reservas/listado/" + new Date().getFullYear() + "/" + (new Date().getMonth() + 1);
}

const sendGetRequest = (usuario: any) => {

  return axios({
      url: `${Constantes.URL_BASE}/validate`,
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'X-Debug-Mode': 'true'
      },
      data: {
        'login': usuario.login,
        'password': usuario.password
      }
    }).then((response) => {
      return response.data;
    });
};

const Home: React.FC<RouteComponentProps> = (props) => {
  
  const initial = {codigo: null, login: '', password: ''};

  const [loading, setLoading] = useState(false);  
  const [login, setLogin] = useState(false);

  const [error, setError] = useState();
  const [usuario, setUsuario] = useState(initial);

  return (
      <PageLayout titulo="PLATEROTECA" back="/">
        {/*-- Input Login --*/}
        <IonItem>
          <IonLabel>Login</IonLabel>
          <IonInput type="text" required
            value={usuario.login}
            onIonChange={ (event) => { setUsuario({ ...usuario, login: (event.target as HTMLInputElement).value}) } }
          />
        </IonItem>

        {/*-- Input Password --*/}
        <IonItem>
          <IonLabel>Password</IonLabel>
          <IonInput type="password" required
            value={usuario.password}
            onIonChange={ (event) => { setUsuario({ ...usuario, password: (event.target as HTMLInputElement).value}) } }
          />
        </IonItem>
        <br />

        {/*-- Button Aceptar --*/}
        <IonButton
          type="submit" expand="full" shape="round" color="primary"
          disabled={usuario.login === '' || usuario.password === '' || login}
          onClick={() => {
            // mostramos modal 'Loading ...'
            setLoading(true);
            
            // invocamos REST validacion
            sendGetRequest(usuario)
              .then((data) => {
                setLogin(true);
                setUsuario(data);
                UsuarioUtils.getInstance().storeUsuario(data);
              })
              .catch((error) => {
                setError(error);
                setUsuario({ codigo: null, login: usuario.login, password: '' });
              })
              .finally(() => {
                setLoading(false);
              });
          }}
        >
          Aceptar
        </IonButton>

        {login &&
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton onClick={() => { props.history.push(dameUrlReservas()); } }>
            <IonIcon name="add" />
          </IonFabButton>
        </IonFab>
        }

        {error &&
          <MensajeUsuario
            mensaje={error}
            callback={() => setError(null)} />
        }

        {loading &&
          <IonLoading
            isOpen={loading}
            onDidDismiss={() => setLoading(false)}
            message={'Loading ...'}
          />
        }

      </PageLayout>
  );
};

channel.bind('RESERVAS', function(data) {
  alert(JSON.stringify(data));
});

export default Home;
