import Constantes from '../config/Constantes';

class UsuarioUtils {

    /** instancia única de la clase **/
    private static instance: UsuarioUtils;

    /**
     * Constructor privado de la clase.
     */
    private constructor() { }

    /**
     * Devuelve instancia única de la clase.
     */
    public static getInstance(): UsuarioUtils {

        // validamos valor instancia
        if (!UsuarioUtils.instance) {
            // inicializamos instancia de la clase
            UsuarioUtils.instance = new UsuarioUtils();
        } // if

        // devolvemos instancia de la clase
        return UsuarioUtils.instance;

    } // getInstance

    /**
     * Guarda los datos del usuario.
     * 
     * @param usuario objeto JSON
     */    
    public storeUsuario(usuario: any) {

        // devolvemos ID dde usuario
        window.localStorage['usuario'] = JSON.stringify({...usuario, password: '********'});

    } // storeUsuario

    /**
     * Devuelve true si existe sesión usuario.
     */
    public isUsuarioLogado() {

        // devolvemos ID de usuario
        return JSON.parse(window.localStorage.getItem('usuario')
            || JSON.stringify({codigo: null, login: null, password: null})).codigo !== null;

    } // isUsuarioLogado

    /**
     * Devuelve ID del usuario.
     */
    public getIdUsuario() {

        // devolvemos ID de usuario
        return JSON.parse(window.localStorage.getItem('usuario')
            || JSON.stringify({codigo: null, login: null, password: null})).codigo;

    } // getIdUsuario

    /**
     * Devuelve imagen avatar asociada al usuario.
     */
    public getImagenAvatar() {

        // definicion de variables del metodo
        const usuario = JSON.parse(window.localStorage.getItem('usuario')
            || JSON.stringify({codigo: null, login: null, password: null}));

        // devolvemos ID de usuario
        return `${Constantes.URL_FIREBASE_AVATARES}/o/avateres%2F${usuario.login}-${usuario.codigo}.jpg?alt=media&token=da5d3030-2057-436d-bb01-83bca5f7eb7b`;

    } // getImagenAvatar

};

export default UsuarioUtils;
