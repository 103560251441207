export default class Constantes {
    // nombre aplicación
    static APP_NAME = "PLATEROTECA";

    // endoint servicios REST
    static URL_BASE = "https://245236be.eu-gb.apigw.appdomain.cloud/plateroteca-reservas";

    /// endpoint FIREBASE STORAGE / avatares
    static URL_FIREBASE_AVATARES = "https://firebasestorage.googleapis.com/v0/b/plateroteca-5fd5e.appspot.com";

    // endpoint API Google Maps
    static API_KEY_GOOGLE_MAP = "AIzaSyDpdt1TuLGjL7CAZq7cJbCFLtrgybtF9O4";
    static URL_API_GOOGLE_MAP = `https://maps.googleapis.com/maps/api/geocode/json?key=${Constantes.API_KEY_GOOGLE_MAP}`;
}
